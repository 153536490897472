// Dependencies
import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
// Hooks
// ...
// Components
import Layout from "../components/Layout";
import Gallery from "../components/Gallery";
import PageContent from "../components/PageContent";

const ActivityTemplate = ({ data, pageContext }) => {

	// Content
	const { title, metaTitle, metaDescription, shareImage, slides } = data.contentfulActivity;

  return (
    <Layout 
			title={metaTitle || title} 
			description={metaDescription} 
			shareImage={shareImage && shareImage.fluid.src} 
			headerTitle={pageContext.title} 
			invertPage
		>
			<Gallery slides={slides} invertPage />

			<PageContent {...data.contentfulActivity} />
    </Layout>
  )
}

ActivityTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default ActivityTemplate

export const ActivityTemplatePageQuery = graphql`
  query ActivityTemplateQuery($slug: String!) {
    contentfulActivity(slug: {eq: $slug}) {
			metaTitle
			metaDescription
			shareImage: thumbnail {
				fluid(maxWidth: 600) {
					src
				}
			}
      title
			slug
			slides {
				image {
					fluid(maxWidth: 1600) {
						...GatsbyContentfulFluid_noBase64
					}
					portrait: gatsbyImageData(
						layout: CONSTRAINED
						width: 1170
						height: 1800
						quality: 80
						formats: JPG
						resizingBehavior: FILL
					)
					landscape: gatsbyImageData(
						layout: FULL_WIDTH
						quality: 80
						formats: JPG
					)
				}
				description {
					raw
					references {
						... on ContentfulCompanyValue {
							contentful_id
            	__typename
							colour
						}
					}
				}
			}
			information {
				raw
			}
			details {
				raw
				references {
					... on ContentfulAsset {
            contentful_id
            __typename
            id
						title
						file {
							url
						}
            fluid(maxHeight: 800) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
				}
			}
    }
  }
`
